/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
require('./bootstrap');

import $ from 'jquery';
window.$ = window.jQuery = $;

import 'jquery-ui/ui/widgets/datepicker.js';

//require('@fancyapps/fancybox');
//require('@fancyapps/fancybox/dist/jquery.fancybox.min.css');

$(function () {
	/*
  	* Fancybox
  	*/
  	/*$().fancybox({
		selector : '[data-fancybox]',
		margin : [80, 0],
		protect: true,
		arrows: false,
		hash : false,
		buttons : ['close'],
	});*
**/	


	$(".date").datepicker({
	    dateFormat: 'dd/mm/yy',
	    dayNames: ['Domingo','Segunda','Terça','Quarta','Quinta','Sexta','Sábado'],
	    dayNamesMin: ['D','S','T','Q','Q','S','S','D'],
	    dayNamesShort: ['Dom','Seg','Ter','Qua','Qui','Sex','Sáb','Dom'],
	    monthNames: ['Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
	    monthNamesShort: ['Jan','Fev','Mar','Abr','Mai','Jun','Jul','Ago','Set','Out','Nov','Dez'],
	    nextText: 'Próximo',
	    prevText: 'Anterior'
	});

	/*
  	* Navbar
  	**/
	$('#header').on('show.bs.dropdown', function(){
	    $('body').addClass('modal-open');
	});
	$('#header').on('hide.bs.dropdown', function(){
	    return false;
	});
	$('.navbar-dismiss').on('click', function(){
		$('#header').removeClass('open');
		$('body').removeClass('modal-open');
	});

  $('[data-toggle="tooltip"]').tooltip({
  	container: 'body'
  });

  $( '.sublink-active' ).each( function () {
    let trigger = $( this ).parent().parent().parent().find( '[data-toggle="collapse"]' );
    trigger.click();
  });


  /*
  * Special buttons
  **/
  /*$( '.reload-page' ).click( function () {
    setTimeout( function () {
      window.location.reload();
    }, 100 );
  });*/
  $('#ordenar').on('hide.bs.modal', function (e) {
  	setTimeout( function () {
      window.location.reload();
    }, 100 );
  });

  /*
  * Change item status
  **/
  $( '.change-status' ).click( function () {
    let item_id = this.value;
    $.ajax({
      type: 'GET'
      , url: `${window.Route}/${item_id}/status`
    });
  });

  /*
  * File name (input[type="file"])
  **/
  $( 'input[type="file"]' ).change( function (el) {
  	let label_name = $(this).parent().find( '.file-name' );
  	if ( !this.files.length ) {
  		label_name.text( 'Nenhum arquivo selecionado.' );
  	} else if ( this.files.length === 1 ) {
  		label_name.text( `Arquivo selecionado: ${this.files[ 0 ].name}` );
	  } else {
  		label_name.text( `${this.files.length} arquivos selecionados.` );
	  }
  });

/*
  * Remove files
  **/

  $( '.remove-file' ).click( function () {
    let url = $(this).attr('data-url'); 
   
    $.ajax({
      type: 'GET'
      , url: url
    });
  });

  $( '.remove-image-gallery' ).click( function () {
    let url = $(this).attr('data-url'); 

   	$(this).parent().parent().parent().parent().fadeOut(300, function() { $(this).remove(); })
   
    $.ajax({
      type: 'GET'
      , url: url
    });

    location.reload();

  });
  

  /*
  * Contador de caracteres
  **/
	var Counter = function ( maxlength ) {
		var counter_badge = document.createElement( 'span' );
		counter_badge.className = 'badge circle form-counter-label';

		counter_badge.set_counter = function ( chars ) {
			var message = '';
			if ( chars > 1 ) {
				message = `${chars} caracteres restantes.`;
			} else if ( chars === 0 ) {
				message = `Limite atingido.`;
			} else {
				message = `1 caractere restante.`;
			}
			counter_badge.innerText = message;
		};

		return counter_badge;
	};

	/*
	* Chama os inputs com contador
	**/
	$('.form-counter').each(function() {

		var maxlength = this.getAttribute( 'maxlength' ) * 1;
		if ( !maxlength ) {
			return console.error( 'Defina um número máximo de caracteres para o contador.' );
		}
		var counter = new Counter( maxlength );
		counter.set_counter( maxlength - this.value.length );

		this.parentNode.appendChild( counter );

		$( this )
			.keyup( function () {
				counter.set_counter( maxlength - this.value.length );
			})
			.focusout( function () {
				counter.set_counter( maxlength - this.value.length );
			});
	});



	/*
	* Sortable
	**/
	const setOrder = function () {
		let message = $( this ).parent().find( '.message-order' );
		setTimeout( () => {
			let childs = this.children;
			let ids = [];
			for ( let i = 0, l = childs.length; i < l; i++ ) {
				childs[ i ].dataset.id && ids.push( childs[ i ].dataset.id );
			}

			window.axios.get(window.Route + '/reorder?order=' + ids.join( ';' ))
			.then(response => {
				message.removeClass( 'hidden' );
			})
			.catch(error => {
				console.log('erro, tente novamente.');
			});
		})
	};

	$( '.sortable-photo-sort' )
		.sortable({
			handle: 'img'
		})
		.on( 'sortable:deactivate', function ( e, ui ) {
			setOrder.apply( this );
	  });

	$( '.sortable-gallery' )
		.sortable({
			handle: 'img'
		})
		.on( 'sortable:deactivate', function ( e, ui ) {
			setOrder.apply( this );

			//location.reload();
	  });

	$( '.sortable-text' )
		.sortable()
		.on( 'sortable:deactivate', function ( e, ui ) {
			setOrder.apply( this );
	  });

  /*
  * Checkboxes (select all)
  * ".checkboxes-control" is the table
  **
  $( '.checkboxes-control' ).each( function () {
    let head_checkbox = $( this ).find( 'thead' ).find( 'input[type="checkbox"]' );
    if ( !head_checkbox.data( 'checkboxes' ) ) {
      return console.log( 'O Checkbox do THead não possui a propriedade "data-checkboxes".' );
    }
    let body_checkboxes = $( this ).find( `input[name="${head_checkbox.data( 'checkboxes' )}"]` );

    let changeAll = function () {
      body_checkboxes.attr( 'checked', this.checked );
    };

    changeAll.apply( head_checkbox[ 0 ] );
    head_checkbox.change( function () { changeAll.apply( this ) } );

  });
  */

});
